<template>
    <div>
        <div v-if="!linkData && isValidUrl" id="loader-container" :style="{ width: maxWidth }">
            <slot name="loading">
                <loading-spinner />
            </slot>
        </div>
        <div v-if="linkData" class="relative inline transition duration-200 ease-in-out">
            <div class="relative inline-flex items-center w-full cursor-pointer">
                <div class="bg-white">
                    <div class="wrapper" >
                        <div class="card-img">
                            <img :src="linkData.image" class="object-cover w-full " :alt="linkData.title"/>
                        </div>
                        <div class="p-4 bg-white card-info">
                            <div class="w-4/5 mx-auto card-text">
                                <h1 class="mb-2 text-xl font-semibold text-center text-gray-600">{{ linkData.title }}</h1>
                                <p class="text-xs text-center text-gray-400">{{ linkData.description }}</p>
                            </div>
                                <div class="mt-4 text-center card-btn">
                                    <GenericButton @click="openLink"  theme="inherit-contained" :text="$t('View More')" class="px-4 py-2 text-white transition duration-200 rounded-full hover:bg-[#016A6D]"/>
                                </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script setup>
import {ref, onMounted} from 'vue';
import LoadingSpinner from "@/Components/LoadingSpinner.vue";
import GenericButton from "@/Components/GenericButton.vue";

const props = defineProps({
    src: {},
    isPreview: {default: false},
    width: {default: "90%"},
    maxWidth: {default: "300px"},
    marginTop: {default: "18px"},
    marginBottom: {default: "18px"},
    marginRight: {default: "auto"},
    marginLeft: {default: "auto"},
    customDomain: {default: route('feed.parse-link')},
    canOpenLink: {type: Boolean, default: true}
});

const emit = defineEmits(["onClick"]);

const loading = ref(false);
const linkData = ref(null);
const isValidUrl = ref(false);

const urlRegex = /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/gi;
isValidUrl.value = urlRegex.test(props.src);

const fetchLinkData = async () => {
    try {
        const response = await axios.post(props.customDomain, {link: props.src }, {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
        });
        linkData.value = response.data.data;
        loading.value = false;
    } catch (error) {
        console.error('Error fetching link preview data:', error);
    }
};

const openLink = () => {
    if (props.canOpenLink) {
        window.open(props.src, "_blank");
    }
    emit("onClick", linkData.value);
};

const styles = () => ({
    width: props.width,
    maxWidth: props.maxWidth,
    marginTop: props.marginTop,
    marginBottom: props.marginBottom,
    marginRight: props.marginRight,
    marginLeft: props.marginLeft
});

onMounted(fetchLinkData);
</script>

<style scoped>
.wrapper {
    overflow: auto;
    /*border-radius: 7px;*/
    background-color: #fff;
    /*-webkit-box-shadow: 0px 14px 32px 0px rgba(0, 0, 0, .15);
    -moz-box-shadow: 0px 14px 32px 0px rgba(0, 0, 0, .15);
    box-shadow: 0 14px 32px #00000026;*/
}

</style>
