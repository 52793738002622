<script setup>
import { ref, watch ,computed} from 'vue';
import { useForm, usePage, } from "@inertiajs/vue3";
import Swal from 'sweetalert2';
import dayjs from 'dayjs';
import Modal from "@/Components/Modal.vue";
import InputError from '@/Components/InputError.vue';
import InputLabel from '@/Components/InputLabel.vue';
import TextInput from '@/Components/TextInput.vue';
import FormButton from '@/Components/Partials/FormButton.vue';
import GenericButton from "@/Components/GenericButton.vue";
import VueDatePicker from '@vuepic/vue-datepicker';
import { CheckIcon } from "@heroicons/vue/24/solid";
import SearchTagsInput from "@/Components/SearchTagsInput.vue";


const emit = defineEmits(['update:toggleModal']);
const props = defineProps({
    modalOpen: Boolean,
    routeName: {
        type: String
    },
    category : {
        type: String,
        default: null
    },
    event: {
        type: Object,
        default: () => ({})
    },
    users: {
        type: Array,
        default: () => [] //todo please check it JobCandidates.vue > InterviewEvent.vue > AddEventModal.vue > users. jobCandidates doesn't have users
    }
});

const options = ref(props.users.map(item => item.email));
const isHr = computed(() => usePage()?.props?.loginType === 'hr')
const isFullDay = ref(false);
const fullDayDate = ref(new Date());
const currentDate = new Date();
currentDate.setMinutes(0);
const form = useForm({
    id: '',
    title: '',
    description: '',
    attendeeEmails:[],
    fullDay: false,
    startDate: new Date(currentDate),
    endDate: new Date(currentDate),
    color: '',
    category: props.category,
    selectedColor: false,
});
const colors = ref([
    { name: 'blue', value: 'blue' },
    { name: 'yellow', value: 'yellow' },
    { name: 'green', value: 'green' },
    { name: 'red', value: 'red' },
]);

watch(() => props.modalOpen, (newValue) => {
    if (!newValue) {
        form.reset();
        isFullDay.value = false;
    }
});

const checkboxChanged = () => {
    isFullDay.value = !isFullDay.value;
}

const handleDate = (modelData) => {
    form.startDate = new Date(modelData);
    form.startDate.setMinutes(0);
    form.endDate = new Date(modelData);
    form.endDate.setHours(23, 59, 59, 999);
}

const handleNonWholeDayReservation = (startDate) => {
    console.log(startDate)
    form.startDate = new Date(startDate);
    form.endDate = new Date(form.startDate);
    form.endDate.setHours(form.endDate.getHours() + 1);
}
const url = ref(props.routeName);
const saveEvent = () => {
    const formattedStartDate = dayjs(form.startDate).format('YYYY-MMM-DD HH:mm:ss');
    const formattedEndDate = dayjs(form.endDate).format('YYYY-MMM-DD HH:mm:ss');
    form.startDate = formattedStartDate;
    form.endDate = formattedEndDate;

    form.post(url.value, {
        onSuccess: () => {
            emit('update:toggleModal');
            Swal.fire({
                title: "Success",
                toast: true,
                text: "Event Created Successfully!",
                icon: "success",
                showConfirmButton: false,
                timer: 6000
            });
            location.reload();
        }
    });
}

const isValidEmail = (email) => {
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailPattern.test(email);
    };
const validateEmails =() => {
    const errors = [];
    form.attendeeEmails.forEach(email => {
        if (!isValidEmail(email)) {
            errors.push(email);
            setTimeout(() => {
                form.attendeeEmails.pop(email)
            }, 2000);
        }
    });
    if (errors.length > 0) {
        form.errors.attendeeEmails = `Invalid email addresses: ${errors.join(', ')}`;
    } else {
        form.errors.attendeeEmails = null;
    }
};
watch(() => form.attendeeEmails, (newEmails) => {
    validateEmails();
}, { deep: true });

watch(() => props.event, (newEvent) => {
    url.value = route('agendas.update.event', newEvent.id??0);
    form.id = newEvent.id ?? '';
    form.title = newEvent.title ?? '';
    form.description = newEvent.description ?? '';
    form.attendeeEmails = newEvent.attendeeEmails ?? [];
    form.fullDay = newEvent.all_day == 1 ?? false;
    form.startDate = newEvent.start_time ?? new Date(currentDate);
    form.endDate = newEvent.end_time ?? new Date(currentDate);
    form.color = newEvent.color ?? '';
    form.selectedColor = newEvent.color ?? false;
}, { deep: true });
</script>

<template>
    <Modal @close="$emit('update:toggleModal')" :show="modalOpen" max-width="xl">
        <h1 class="mb-6 text-xl font-semibold"> {{ $t('New Event') }} {{ ucfirst(props.category) }}</h1>
        <form @submit.prevent="saveEvent" method="POST">
            <div class="w-full mb-6">
                <TextInput
                    id="name"
                    type="text"
                    class="w-full p-4 text-base text-gray-900 shadow ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-main sm:leading-6"
                    v-model="form.title"
                    autofocus
                    autocomplete="off"
                    :placeholder="$t('Title')"
                />
                <InputError class="mt-2" :message="form.errors.title" />
            </div>
            <div class="w-full mb-6">
                <textarea
                    v-model="form.description"
                    id="description"
                    name="description"
                    rows="5"
                    :placeholder="$t('Description')"
                    class="block w-full text-lg text-gray-900 rounded-md shadow ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-main sm:leading-6"
                ></textarea>
                <InputError class="mt-2" :message="form.errors.description" />
            </div>

            <div class="mt-4"  v-if="isHr">
                <InputLabel for="skills" :value="$t('Attendee Emails')" />
                <SearchTagsInput
                    v-model="form.attendeeEmails"
                    :options="options"
                    :allow-custom="true"
                    :show-count="true"
                    />
                <InputError class="mt-2" :message="form.errors.attendeeEmails" />
            </div>

            <div class="w-full mb-6">
                <InputLabel for="firstname" value="All day" class="mb-4 mr-4" />
                <label class="inline-flex items-center cursor-pointer">
                    <input type="checkbox" @change="checkboxChanged" v-model="form.fullDay" class="sr-only peer" />
                    <div class="relative w-11 h-6 bg-gray-400 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-gray-300 rounded-full peer peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-gray-600"></div>
                </label>
                <InputError class="mt-2" :message="form.errors.fullDay" />
            </div>
            <div class="flex items-center justify-between gap-6 mb-8" v-if="!isFullDay">
                <div class="w-full">
                    <div class="flex flex-row">
                        <div class="w-full">
                            <InputLabel for="Start Date" value="Start Date" class="mb-4 mr-4" />
                            <VueDatePicker
                                v-model="form.startDate"
                                @update:model-value="handleNonWholeDayReservation"
                                time-picker-inline
                                :teleport="true"
                                minutes-grid-increment="30"
                            />
                            <InputError class="mt-2" :message="form.errors.startDate" />
                        </div>
                    </div>
                </div>
                <div class="w-full">
                    <div class="flex flex-row">
                        <div class="w-full">
                            <InputLabel for="endDate" value="End Date" class="mb-4 mr-4" />
                            <VueDatePicker
                                v-model="form.endDate"
                                time-picker-inline
                                :teleport="true"
                                minutes-grid-increment="30"
                            />
                            <InputError class="mt-2" :message="form.errors.endDate" />
                        </div>
                    </div>
                </div>
            </div>
            <div class="w-full mb-6" v-if="isFullDay">
                <div class="w-full">
                    <div class="flex flex-row">
                        <div class="w-full">
                            <InputLabel for="endDate" value="Select Date" class="mb-4 mr-4" />
                            <VueDatePicker
                                v-model="fullDayDate"
                                :enable-time-picker="false"
                                :teleport="true"
                                @update:model-value="handleDate"
                            />
                            <InputError class="mt-2" :message="form.errors.endDate" />
                        </div>
                    </div>
                </div>
            </div>
            <div class="flex mb-12">
                <div class="w-full">
                    <div class="flex flex-row">
                        <div class="color-selector">
                            <div v-for="color in colors" :key="color.name" class="color-item">
                                <input
                                    type="radio"
                                    @change="getselectedColor"
                                    :id="color.name"
                                    :value="color.value"
                                    v-model="form.selectedColor"
                                    class="hidden"
                                />
                                <label
                                    :for="color.name"
                                    :style="{ backgroundColor: color.value }"
                                    class="flex items-center justify-center w-8 h-8 rounded-full !shadow"
                                >
                                    <CheckIcon
                                        class="w-6 h-6 text-center text-white"
                                        v-if="color.value === form.selectedColor"
                                    />
                                </label>
                            </div>
                        </div>
                    </div>
                    <InputError class="mt-2" :message="form.errors.color" />
                </div>
            </div>
            <div class="flex justify-end items-center pb-3 text-gray-500 font-semibold gap-2 text-lm font-['Montserrat'] leading-snug">
                <GenericButton
                    @click="$emit('update:toggleModal')"
                    :text="$t('Discard')"
                    theme="inherit-outlined"
                    type="button"
                    class="p-2 rounded"
                >
                    {{ $t('Cancel') }}
                </GenericButton>
                <GenericButton
                    type="submit"
                    class="cursor-pointer"
                    :text="$t('Save Changes')"
                />

            </div>
        </form>
    </Modal>
</template>

<style scoped>
.v-menu__content {
    display: flex;
    flex-wrap: wrap;
    max-height: none;
    min-width: auto;
}

.color-selector {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
}

.color-item {
    flex: 0 0 auto;
}
</style>
