<script>
import ApplicationLogo from "@/Components/ApplicationLogo.vue";
import IconChat from "@/Icons/Chat.vue";
import IconCountries from "@/Icons/Countries.vue";
import IconCompanies from "@/Icons/Companies.vue";
import IconRegions from "@/Icons/Regions.vue";
import IconGroup from "@/Icons/Group.vue";
import IconJourney from "@/Icons/journey-icon.vue";
import IconAgenda from "@/Icons/agenda.vue";
import IconDown from "@/Icons/arrow-down.vue";
import IconCandidates from "@/Icons/candidates.vue";
import IconJobs from "@/Icons/jobs.vue";
import IconNewsFeed from "@/Icons/newsFeed.vue";
import { Head, Link } from "@inertiajs/vue3";
import LiveChat from "@/Layouts/Partials/LiveChat.vue";
import axios from "axios";
//import ProposeRegister from "../Partials/ProposeRegister.vue";

import { ref } from "vue";
import IconPayPeek from "@/Icons/IconPayPeek.vue";
//import { Dialog, DialogTitle, DialogPanel, DialogDescription } from '@headlessui/vue'

export default {
    components: {
        IconPayPeek,
        ApplicationLogo,
        IconChat,
        IconCompanies,
        IconCountries,
        IconRegions,
        IconGroup,
        IconAgenda,
        IconDown,
        IconCandidates,
        IconJobs,
        IconNewsFeed,
        LiveChat,
        Head,
        IconJourney,
        Link,
        //  ProposeRegister,
        //  Dialog,
        //  DialogTitle,
        //  DialogPanel,
        //  DialogDescription
    },
    props: {},
    emits: ["close-menu"],
    data() {
        return {
            activeUrl: window.location.pathname,
            showJobs: false,
            unreadMessagesCount: 0,
            css: {
                bull_active: "w-2 h-2 bg-main rounded-full",
                bull: "w-1 h-1 bg-gray-500 rounded-full",
                label_active: "text-main font-semibold",
                label_active_sub: "text-black font-semibold",
                label: "text-secondary md:font-medium",
                bg_active: "md:bg-main md:bg-opacity-10 active",
                bg_inactive:
                    "md:bg-white md:bg-opacity-0 transition-all md:hover:bg-grey-8",
            },
            isOpen: ref(true),
        };
    },
    computed: {
        authuser() {
            return this.$page.props.auth?.user;
        },
    },
    methods: {
        isJobListUrl(url) {
            return ["/countries", "/companies", "/regions", "/jobs"].some(
                (prefix) => url.startsWith(prefix)
            );
        },
        toggleJobs() {
            this.showJobs = !this.showJobs;
        },
        isActive(url) {
            return this.activeUrl === url;
        },
        updateActiveUrl(url) {
            this.$emit("close-menu");
            this.activeUrl = url;
        },
        closeMenu() {
            this.$emit("close-menu");
        },
        loadUnreadMessagesCount() {
            if (this.authuser) {
                axios
                    .get(route("chat.unread-messages"))
                    .then((response) => {
                        if (response.data.unreadMessagesCount > 0) {
                            this.unreadMessagesCount =
                                response.data.unreadMessagesCount;
                        }
                    })

                    .catch((error) => {
                        console.error(error);
                    });
            }
        },
        setIsOpen(value) {
            this.isOpen.value = value;
        },
    },
    mounted() {
        // Execute this code when the component is mounted
        if (this.isJobListUrl(this.activeUrl)) {
            this.showJobs = true;
        }

        // Load chat count if user is authenticated
        this.loadUnreadMessagesCount();
    },
};
</script>

<template>
    <div
        class="w-full overflow-auto shrink-0 bottom-0 md:px-4 md:pb-10 bg-[rgba(255,255,255,0.7)] md:bg-white md:border-r md:border-gray-400 md:border-opacity-20 flex-col items-center inline-flex z-[60] md:transition-none fixed md:relative md:top-0 md:h-auto md:z-20 md:w-[280px] left-0 transition-all duration-300 backdrop-blur md:duration-0"
    >
        <div class="pt-6 px-4 pb-2.5 w-full hidden items-center md:flex">
            <ApplicationLogo class="max-w-[168px]" />
            <!--<img class="self-stretch grow shrink basis-0" src="https://via.placeholder.com/168x34" /> -->
        </div>
        <div
            class="flex items-center self-stretch gap-1 px-4 mx-auto md:w-full md:mx-0 w-fit md:items-start md:p-2 h-14 md:h-full md:justify-start md:flex-col md:grow md:shrink md:basis-0"
        >
            <div
                class="items-center self-stretch justify-start hidden pt-4 pb-2 pl-3 bg-white rounded-lg md:inline-flex"
            >
                <div
                    class="text-gray-400 text-[11px] font-bold uppercase leading-[18px]"
                >
                    {{ $t("Foundation") }}
                </div>
            </div>
            <!--
             FEED ACTIVE: {{ isActive('/feed') }}   <br/>
             CLASS A: {{ isActive('/feed')
                        ? css.bg_active
                        : css.bg_inactive }}<br/>
             CLASS TEXT: {{ isActive('/feed') ? css.label_active : css.label }}
            -->
            <Link
                :href="route('feed')"
                @click.prevent="updateActiveUrl('/')"
                :class="isActive('/') ? css.bg_active : css.bg_inactive"
                class="inline-flex flex-col items-center justify-start flex-shrink-0 transition-all md:w-full md:rounded-lg md:bg-white md:hover:bg-grey-8 min-w-16 md:max-w-none md:p-2 md:pl-3 md:pr-2 md:flex-row min-h-11 group"
            >
                <div class="flex items-center justify-center md:pr-4">
                    <div class="relative w-6 h-6">
                        <IconNewsFeed />
                    </div>
                </div>
                <div
                    class="inline-flex flex-col items-center justify-center text-center md:pr-4 grow shrink basis-0 md:text-left md:items-start md:justify-start"
                >
                    <div
                        class="text-[10px] md:text-sm"
                        :class="isActive('/') ? css.label_active : css.label"
                    >
                        {{ $t("News feed") }}
                    </div>
                </div>
            </Link>

            <Link
                :href="route('jobs')"
                class="inline-flex flex-col items-center justify-start flex-shrink-0 transition-all md:w-full md:rounded-lg md:bg-white md:hover:bg-grey-8 min-w-16 md:max-w-none md:p-2 md:pl-3 md:pr-2 md:flex-row min-h-11 group"
                :class="isActive('/jobs') ? css.bg_active : css.bg_inactive"
            >
                <div class="flex items-center justify-center md:pr-4">
                    <div class="relative w-6 h-6">
                        <!-- Jobs -->
                        <IconJobs />
                    </div>
                </div>
                <div
                    class="inline-flex flex-col items-center justify-center text-center md:pr-4 grow shrink basis-0 md:text-left md:items-start md:justify-start"
                >
                    <div
                        class="text-[10px] md:text-sm"
                        :class="
                            isActive('/jobs') ? css.label_active : css.label
                        "
                    >
                        {{ $t("Jobs") }}
                    </div>
                </div>
                <div
                    class="items-center justify-center hidden pl-8 md:flex"
                    @click.prevent="toggleJobs"
                >
                    <div
                        class="relative w-4 h-4 -rotate-90 group-[&.active]:rotate-180"
                    >
                        <IconDown />
                    </div>
                </div>
            </Link>

            <!-- Start Jobs -->
            <Link
                :href="route('jobs')"
                @click.prevent="updateActiveUrl('/jobs')"
                v-if="showJobs"
                class="items-center self-stretch justify-start hidden pl-3 pr-2 transition-all bg-opacity-0 rounded-lg md:inline-flex hover:bg-grey-8 group"
            >
                <div class="flex items-center justify-center md:pr-4">
                    <div class="flex items-center justify-center w-6 h-6">
                        <div
                            :class="
                                isActive('/jobs') ? css.bull_active : css.bull
                            "
                        ></div>
                    </div>
                </div>
                <div
                    class="inline-flex flex-col items-center justify-center text-center md:pr-4 grow shrink basis-0 md:text-left md:items-start md:justify-start"
                >
                    <div
                        class="text-sm"
                        :class="
                            isActive('/jobs') ? css.label_active_sub : css.label
                        "
                    >
                        {{ $t("All jobs") }}
                    </div>
                </div>
            </Link>

            <Link
                href="/jobs/applied"
                @click.prevent="updateActiveUrl('/jobs/applied')"
                v-if="showJobs"
                class="items-center self-stretch justify-start hidden pl-3 pr-2 transition-all bg-opacity-0 rounded-lg md:inline-flex hover:bg-grey-8 group"
            >
                <div class="flex items-center justify-center md:pr-4">
                    <div class="flex items-center justify-center w-6 h-6">
                        <div
                            :class="
                                isActive('/jobs/applied')
                                    ? css.bull_active
                                    : css.bull
                            "
                        ></div>
                    </div>
                </div>
                <div
                    class="inline-flex flex-col items-center justify-center text-center md:pr-4 grow shrink basis-0 md:text-left md:items-start md:justify-start"
                >
                    <div
                        class="text-sm"
                        :class="
                            isActive('/jobs/applied')
                                ? css.label_active_sub
                                : css.label
                        "
                    >
                        {{ $t("Applied") }}
                    </div>
                </div>
            </Link>

            <Link
                href="/jobs/saved"
                @click.prevent="updateActiveUrl('/jobs/saved')"
                v-if="showJobs"
                class="items-center self-stretch justify-start hidden pl-3 pr-2 transition-all bg-opacity-0 rounded-lg md:inline-flex hover:bg-grey-8 group"
            >
                <div class="flex items-center justify-center md:pr-4">
                    <div class="flex items-center justify-center w-6 h-6">
                        <div
                            :class="
                                isActive('/jobs/saved')
                                    ? css.bull_active
                                    : css.bull
                            "
                        ></div>
                    </div>
                </div>
                <div
                    class="inline-flex flex-col items-center justify-center text-center md:pr-4 grow shrink basis-0 md:text-left md:items-start md:justify-start"
                >
                    <div
                        class="text-sm"
                        :class="
                            isActive('/jobs/saved')
                                ? css.label_active_sub
                                : css.label
                        "
                    >
                        {{ $t("Saved") }}
                    </div>
                </div>
            </Link>

            <!-- End Jobs -->
            <!-- <Link
                :href="route('events.index', 'user')"
                @click.prevent="updateActiveUrl('/events/user')"
                :class="
                    isActive('/events/user') ? css.bg_active : css.bg_inactive
                "
                class="inline-flex flex-col items-center justify-start flex-shrink-0 transition-all md:w-full md:rounded-lg md:bg-white md:hover:bg-grey-8 min-w-16 md:max-w-none md:p-2 md:pl-3 md:pr-2 md:flex-row min-h-11 group"
            >
                <div class="flex items-center justify-center md:pr-4">
                    <div class="relative w-6 h-6">
                        <IconAgenda />
                    </div>
                </div>
                <div
                    class="inline-flex flex-col items-center justify-center text-center md:pr-4 grow shrink basis-0 md:text-left md:items-start md:justify-start"
                >
                    <div
                        class="text-[10px] md:text-sm"
                        :class="
                            isActive('/events/user')
                                ? css.label_active
                                : css.label
                        "
                    >
                        {{ $t("Events") }}
                    </div>
                </div>
            </Link> -->
            <Link
                :href="route('job', { name: 'companies' })"
                @click.prevent="updateActiveUrl('/companies')"
                :class="
                    isActive('/companies') ? css.bg_active : css.bg_inactive
                "
                class="inline-flex flex-col items-center justify-start flex-shrink-0 transition-all md:w-full md:rounded-lg md:bg-white md:hover:bg-grey-8 min-w-16 md:max-w-none md:p-2 md:pl-3 md:pr-2 md:flex-row min-h-11 group"
            >
                <div class="flex items-center justify-center md:pr-4">
                    <div
                        class="relative flex items-center justify-center w-6 h-6"
                    >
                        <IconCompanies />
                    </div>
                </div>
                <div
                    class="inline-flex flex-col items-center justify-center text-center md:pr-4 grow shrink basis-0 md:text-left md:items-start md:justify-start"
                >
                    <div
                        class="text-[10px] md:text-sm"
                        :class="
                            isActive('/companies')
                                ? css.label_active
                                : css.label
                        "
                    >
                        {{ $t("Companies") }}
                    </div>
                </div>
            </Link>

            <!-- 	<Link
				:href="route('job', { name: 'countries' })"
                @click.prevent="updateActiveUrl('/countries')"
                :class="isActive('/countries') ? css.bg_active : css.bg_inactive"
                class="inline-flex items-center self-stretch justify-start p-2 pl-3 pr-2 transition-all rounded-lg min-h-11 hover:bg-grey-8 group"
            >
                <div class="flex items-center justify-center md:pr-4">
                    <div class="relative items-center justify-center w-6 h-6">
                        <IconCountries />
                    </div>
                </div>
                <div class="inline-flex flex-col items-center justify-center text-center md:pr-4 grow shrink basis-0 md:text-left md:items-start md:justify-start">
                    <div :class="isActive('/countries') ? css.label_active : css.label">
                        {{ $t("Countries") }}
                    </div>
                </div>

            </Link> -->

            <Link
                :href="route('job', { name: 'regions' })"
                @click.prevent="updateActiveUrl('/regions')"
                :class="isActive('/regions') ? css.bg_active : css.bg_inactive"
                class="flex-col items-center justify-start flex-shrink-0 hidden transition-allmd:inline-flex md:w-full md:rounded-lg md:bg-white md:hover:bg-grey-8 min-w-16 md:max-w-none md:p-2 md:pl-3 md:pr-2 md:flex-row min-h-11 group"
            >
                <div class="flex items-center justify-center md:pr-4">
                    <div
                        class="relative flex items-center justify-center w-6 h-6"
                    >
                        <IconRegions />
                    </div>
                </div>
                <div
                    class="inline-flex flex-col items-center justify-center text-center md:pr-4 grow shrink basis-0 md:text-left md:items-start md:justify-start"
                >
                    <div
                        class="text-[10px] md:text-sm"
                        :class="
                            isActive('/regions') ? css.label_active : css.label
                        "
                    >
                        {{ $t("Regions") }}
                    </div>
                </div>
            </Link>
            <!-- <Link
                :href="route('chat.index')"
                @click.prevent="updateActiveUrl('/chat')"
                :class="isActive('/chat') ? css.bg_active : css.bg_inactive"
                class="flex-col items-center justify-start flex-shrink-0 hidden transition-all bg-white md:inline-flex md:w-full md:rounded-lg md:bg-white md:hover:bg-grey-8 min-w-16 md:max-w-none md:p-2 md:pl-3 md:pr-2 md:flex-row min-h-11 group"
            >
                <div class="flex items-center justify-center md:pr-4">
                    <div class="relative w-6 h-6">
                        <IconChat />
                    </div>
                </div>
                <div
                    class="flex-col items-center justify-center text-center md:pr-4 grow shrink basis-0 md:text-left md:items-start md:justify-start"
                >
                    <div
                        class="text-[10px] md:text-sm"
                        :class="
                            isActive('/chat') ? css.label_active : css.label
                        "
                    >
                        {{ $t("Chat") }}
                    </div>
                </div>
                <div
                    v-if="unreadMessagesCount"
                    class="w-fit px-1.5 py-1 bg-main bg-opacity-20 rounded-md justify-start items-center gap-1.5 flex"
                >
                    <div
                        class="text-center text-main text-xs font-bold font-[Montserrat] leading-tight"
                    >
                        {{ unreadMessagesCount }}
                    </div>
                </div>
            </Link> -->

            <!-- <Link
                :href="route('agendas.index', 'candidate')"
                @click.prevent="updateActiveUrl('/agenda/candidate')"
                :class="isActive('/agenda/candidate') ? css.bg_active : css.bg_inactive"
                class="inline-flex items-center self-stretch justify-start p-2 pl-3 pr-2 transition-all rounded-lg min-h-11 hover:bg-grey-8 group"
            >
                <div class="flex items-center justify-center md:pr-4">
                    <div class="relative w-6 h-6">
                        <IconAgenda />
                    </div>
                </div>
                <div class="inline-flex flex-col items-center justify-center text-center md:pr-4 grow shrink basis-0 md:text-left md:items-start md:justify-start">
                    <div :class="isActive('/agenda/candidate') ? css.label_active : css.label">
                        {{ $t("Agenda") }}
                    </div>
                </div>
            </Link> -->

            <!-- <Link
                :href="route('group.index')"
                @click.prevent="updateActiveUrl('/group')"
                :class="isActive('/group') ? css.bg_active : css.bg_inactive"
                class="inline-flex items-center self-stretch justify-start p-2 pl-3 pr-2 transition-all rounded-lg min-h-11 hover:bg-grey-8 group"
            >
                <div class="flex items-center justify-center md:pr-4">
                    <div class="relative w-6 h-6">

							<IconGroup />
                    </div>
                </div>
                <div class="inline-flex flex-col items-center justify-center text-center md:pr-4 grow shrink basis-0 md:text-left md:items-start md:justify-start">
                    <div :class="isActive('/groups') ? css.label_active : css.label">
                       <span class=" group-[&.active]:!text-main group-[&.active]:font-semibold"> {{ $t("Groups") }}</span>
                    </div>
                </div>
            </Link> -->

            <!-- </div>
		  <div class="flex flex-col items-start self-stretch justify-start gap-1 p-2 grow shrink basis-0"> -->
            <div
                class="items-center self-stretch justify-start hidden pt-4 pb-2 pl-3 rounded-lg md:inline-flex"
            >
                <div
                    class="text-gray-400 text-[11px] font-bold uppercase leading-[18px]"
                >
                    {{ $t("My skills") }}
                </div>
            </div>

            <Link
                href="/journey"
                @click.prevent="updateActiveUrl('/journey')"
                :class="isActive('/journey') ? css.bg_active : css.bg_inactive"
                class="inline-flex flex-col items-center justify-start flex-shrink-0 transition-all md:w-full md:rounded-lg md:bg-white md:hover:bg-grey-8 min-w-16 md:max-w-none md:p-2 md:pl-3 md:pr-2 md:flex-row min-h-11 group"
            >
                <div class="flex items-center justify-center md:pr-4">
                    <div
                        class="relative flex items-center justify-center w-6 h-6"
                    >
                        <IconJourney />
                    </div>
                </div>
                <div
                    class="inline-flex flex-col items-center justify-center text-center md:pr-4 grow shrink basis-0 md:text-left md:items-start md:justify-start"
                >
                    <div
                        class="text-[10px] md:text-sm"
                        :class="
                            isActive('/journey') ? css.label_active : css.label
                        "
                    >
                        {{ $t("Career AI") }}
                    </div>
                </div>
            </Link>
           <Link
                :href="route('pay-peek.index')"
                @click.prevent="updateActiveUrl(route('pay-peek.index'))"
                :class="isActive(route('pay-peek.index')) ? css.bg_active : css.bg_inactive"
                class="inline-flex flex-col items-center justify-start flex-shrink-0 transition-all md:w-full md:rounded-lg md:bg-white md:hover:bg-grey-8 min-w-16 md:max-w-none md:p-2 md:pl-3 md:pr-2 md:flex-row min-h-11 group"
            >
                <div class="flex items-center justify-center md:pr-4">
                    <div class="relative flex items-center justify-center w-6 h-6">
                        <IconPayPeek />
                    </div>
                </div>
                <div class="inline-flex flex-col items-center justify-center text-center md:pr-4 grow shrink basis-0 md:text-left md:items-start md:justify-start">
                    <div
                        class="text-[10px] md:text-sm"
                        :class="isActive(route('pay-peek.index')) ? css.label_active : css.label">
                        {{ $t("PayCheck") }}
                    </div>
                </div>
            </Link>
        </div>
    </div>
    <!-- <LiveChat></LiveChat> -->
</template>
<style>
.BeaconFabButtonFrame {
    bottom: 60px !important;
}
@media (min-width: 740px) {
    .BeaconFabButtonFrame {
        bottom: 10px !important;
    }
}
</style>
