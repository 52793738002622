<script setup></script><template>
   <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path opacity="0.32" fill-rule="evenodd" clip-rule="evenodd" d="M4.12319 2.24369C5.3177 2.12083 7.30475 2 10.5 2C13.6952 2 15.6823 2.12083 16.8768 2.24369C17.8972 2.34863 18.6398 3.10549 18.7572 4.12444C18.8797 5.18775 19 6.7933 19 9C19 11.2067 18.8797 12.8123 18.7572 13.8756C18.6398 14.8945 17.8973 15.6514 16.877 15.7563C15.822 15.8648 14.1489 15.9717 11.571 15.9952C11.1714 15.9989 10.7875 16.1592 10.507 16.4437L8.816 18.1584C8.08185 18.9029 6.8125 18.4707 6.6853 17.4328L6.55145 16.3414C6.52175 16.099 6.3197 15.9137 6.0759 15.9011C5.26545 15.859 4.62502 15.8079 4.12397 15.7564C3.10365 15.6515 2.36022 14.8945 2.24278 13.8756C2.12023 12.8123 2 11.2067 2 9C2 6.7933 2.12023 5.18775 2.24278 4.12444C2.36022 3.10549 3.10287 2.34863 4.12319 2.24369ZM7 6C6.4477 6 6 6.4477 6 7C6 7.5523 6.4477 8 7 8H14C14.5523 8 15 7.5523 15 7C15 6.4477 14.5523 6 14 6H7ZM7 10.5C6.4477 10.5 6 10.9477 6 11.5C6 12.0523 6.4477 12.5 7 12.5H11C11.5523 12.5 12 12.0523 12 11.5C12 10.9477 11.5523 10.5 11 10.5H7Z" class="  group-[&.active]:fill-[#016A6D]   fill-[#637381]"/>
<path d="M6 7C6 6.4477 6.4477 6 7 6H14C14.5523 6 15 6.4477 15 7C15 7.5523 14.5523 8 14 8H7C6.4477 8 6 7.5523 6 7Z" class="  group-[&.active]:fill-[#016A6D]   fill-[#637381]"/>
<path d="M6 11.5C6 10.9477 6.4477 10.5 7 10.5H11C11.5523 10.5 12 10.9477 12 11.5C12 12.0523 11.5523 12.5 11 12.5H7C6.4477 12.5 6 12.0523 6 11.5Z" class="  group-[&.active]:fill-[#016A6D]   fill-[#637381]"/>
<path d="M10.0267 16.9308L10.507 16.4437C10.7876 16.1592 11.1715 15.9989 11.571 15.9952C14.1489 15.9717 15.822 15.8648 16.877 15.7563C17.8973 15.6514 18.6398 14.8945 18.7572 13.8755C18.8373 13.1809 18.9163 12.2547 18.9616 11.0808C19.4683 11.1109 19.8674 11.1462 20.1782 11.1811C20.8037 11.2515 21.2624 11.7033 21.3402 12.3279C21.4216 12.9808 21.5 13.9995 21.5 15.5C21.5 17.0005 21.4216 18.0192 21.3402 18.6721C21.2624 19.2967 20.8031 19.7486 20.1776 19.8189C19.7595 19.8659 19.1818 19.9136 18.3972 19.9483C18.1651 19.9585 17.9683 20.1252 17.9233 20.3531L17.7526 21.219C17.6406 21.7868 16.9548 22.0192 16.5207 21.6364L15.0722 20.3589C14.8039 20.1223 14.4585 19.9901 14.1009 19.9816C12.7859 19.9504 11.9019 19.884 11.3232 19.819C10.6977 19.7488 10.2376 19.2967 10.1598 18.6721C10.1076 18.2528 10.0565 17.6826 10.0267 16.9308Z" class="  group-[&.active]:fill-[#016A6D]   fill-[#637381]"/>
</svg>


    </template>
