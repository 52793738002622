<script setup></script><template>
 
 

<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path opacity="0.32" d="M12 22C17.5228 22 22 20.433 22 18.5C22 16.567 17.5228 15 12 15C6.47715 15 2 16.567 2 18.5C2 20.433 6.47715 22 12 22Z" class="group-[&.active]:fill-[#016A6D] fill-[#637381]"/>
<path d="M12 1.25C12.1989 1.25 12.3897 1.32902 12.5303 1.46967C12.671 1.61032 12.75 1.80109 12.75 2V3.036L17.758 5.54L17.812 5.567C18.546 5.934 19.172 6.247 19.608 6.551C20.05 6.86 20.514 7.307 20.514 8C20.514 8.693 20.05 9.14 19.608 9.449C19.172 9.753 18.546 10.066 17.812 10.433L12.75 12.963V18C12.75 18.1989 12.671 18.3897 12.5303 18.5303C12.3897 18.671 12.1989 18.75 12 18.75C11.8011 18.75 11.6103 18.671 11.4697 18.5303C11.329 18.3897 11.25 18.1989 11.25 18V2C11.25 1.80109 11.329 1.61032 11.4697 1.46967C11.6103 1.32902 11.8011 1.25 12 1.25Z" class="group-[&.active]:fill-[#016A6D] fill-[#637381]"/>
</svg>

    </template>
