<template>
    <div class="h-screen overflow-hidden">
        <div
            class="w-full h-6 text-white bg-[#016A6D] flex items-center justify-center text-center z-50"
        >
            <span class="hidden sm:block">{{
                $t( "Hospitaliti is currently in Beta - your feedback is important to us")
            }}</span>
            <span class="sm:hidden">{{ $t("Hospitaliti is in Beta") }}</span>
        </div>
        <div class="h-full overflow-hidden">
            <!-- <GenericButton
            v-if="isSmallerScreen && isMenuOpen"
            @click="toggleMenu"
            class="flex w-7 h-7 !p-0 cursor-pointer items-center justify-center fixed left-[240px] top-2 bg-transparent"
            style="z-index: 999"
        >
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="none"
            >
                <path
                    d="M1 13L12.94 1M13 13L1.06 1"
                    stroke="black"
                    stroke-width="1.2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
            </svg>
        </GenericButton> -->
            <div class="flex flex-row bg-white max-w-[1440px] mx-auto h-full">
                <component
                    @close-menu="handleCloseMenu"
                    :is="leftComponent"
                    class="bottom-0 w-[280px] shrink-0 h-[54px] left-0 fixed overflow-y-auto md:h-auto transition-all md:relative duration-300 md:duration-0"
                />

                <div
                    class="flex flex-col w-full h-full overflow-hidden md:h-full"
                >
                    <nav class="w-full bg-white max-w-[1160px] mx-auto">
                        <!-- Primary Navigation Menu -->
                        <div class="w-full z-[60] px-4 md:px-6">
                            <div
                                class="flex justify-end py-3 md:flex-row md:py-5"
                            >
                                <!-- Navigation Links -->
                                <div
                                    class="flex-auto hidden space-x-8 md:z-50 sm:-my-px sm:flex"
                                >
                                    <div
                                        class="relative flex items-center flex-auto"
                                    >
                                        <!-- Search -->
                                        <!--
                                   <div class="absolute -translate-y-1/2 top-1/2 left-4">
                                       <Icon type="search" />
                                   </div>
                                   <input v-if="auth.user" type="search" name="search" id="search" autocomplete="off" :placeholder="$t('Search for people')" @keyup="searchUser" v-model="searchItem"
                                       class="w-full text-sm py-2.5 pl-12 pr-4 rounded-full overflow-hidden border border-[#919EAB33] outline-none" />
                                   <input v-else type="search" disabled name="search" id="search" autocomplete="off" :placeholder="$t('Sign in to search')"
                                       class="w-full text-sm py-2.5 pl-12 pr-4 rounded-full overflow-hidden border border-[#919EAB33] outline-none" />
                                -->
                                    </div>
                                </div>

                                <div
                                    class="flex items-center justify-between w-full gap-2"
                                >
                                    <a class="inline-block md:hidden" href="/">
                                        <Icon
                                            type="logo-mobile"
                                            class="flex items-center"
                                        />
                                    </a>
                                    <!-- Hamburger -->
                                    <div
                                        class="flex items-center justify-end ml-auto"
                                    >
                                        <!-- <div
                                        class="flex items-center justify-center mr-auto"
                                        v-if="isSmallerScreen && !isMenuOpen"
                                    >
                                        <GenericButton
                                            @click="toggleMenu"
                                            class="inline-flex w-9 h-9 items-center justify-center !border-none !p-0 text-gray-400 transition duration-150 ease-in-out hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 focus:text-gray-500"
                                        >
                                            <svg
                                                width="20"
                                                height="20"
                                                viewBox="0 0 20 20"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    opacity="0.32"
                                                    d="M12.7332 3.75H3.9335C3.14308 3.75 2.5 4.21714 2.5 4.79202C2.5 5.36619 3.14308 5.83333 3.9335 5.83333H12.7332C13.5236 5.83333 14.1667 5.36619 14.1667 4.79202C14.1667 4.21712 13.5236 3.75 12.7332 3.75Z"
                                                    fill="#637381"
                                                />
                                                <path
                                                    d="M16.0665 8.95833H7.26683C6.47641 8.95833 5.83333 9.42547 5.83333 10.0003C5.83333 10.5745 6.47641 11.0417 7.26683 11.0417H16.0665C16.8569 11.0417 17.5 10.5745 17.5 10.0003C17.5 9.42545 16.857 8.95833 16.0665 8.95833Z"
                                                    fill="#637381"
                                                />
                                                <path
                                                    d="M12.7332 14.1667H3.9335C3.14308 14.1667 2.5 14.6338 2.5 15.2087C2.5 15.7829 3.14308 16.25 3.9335 16.25H12.7332C13.5236 16.25 14.1667 15.7829 14.1667 15.2087C14.1667 14.6338 13.5236 14.1667 12.7332 14.1667Z"
                                                    fill="#637381"
                                                />
                                            </svg>
                                        </GenericButton>
                                    </div> -->
                                        <div class="flex items-center gap-1">
                                            <TopIconMenu />
                                            <!-- Settings Dropdown -->
                                            <div class="relative">
                                                <Dropdown
                                                    align="right"
                                                    width="48"
                                                    v-if="auth.user"
                                                >
                                                    <template #trigger>
                                                        <div class="">
                                                            <GenericButton
                                                                type="button"
                                                                theme="inherit-text"
                                                                class="flex !p-0 w-10 h-10 justify-center !rounded-full border-2 !container border-grey-8"
                                                            >
                                                                <Avatar
                                                                    v-if="auth.user"
                                                                    size="9"
                                                                    class="border-2 border-white rounded-full cursor-pointer"
                                                                />
                                                            </GenericButton>
                                                        </div>
                                                    </template>

                                                    <template #content>
                                                        <DropdownLink
                                                            :href="route('candidate.get')"
                                                            v-if="leftComponent == 'LeftCandidate'"
                                                        >
                                                            {{ $t("Profile") }}
                                                        </DropdownLink>
                                                        <!-- <DropdownLink
                                                        :href="/journey/"
                                                    >
                                                        {{
                                                            $t("Career journey")
                                                        }}
                                                    </DropdownLink> -->
                                                        <DropdownLink
                                                            :href="route('profile.edit')"
                                                            >{{
                                                                $t("Account")
                                                            }}</DropdownLink
                                                        >
                                                        <!-- <DropdownLink :href="route('logout')" method="post" as="button"> -->

                                                        <DropdownLink
                                                            @click="logout"
                                                            as="button"
                                                        >
                                                            {{ $t("Log Out") }}
                                                        </DropdownLink>
                                                    </template>
                                                </Dropdown>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <!-- search result -->
                                <div
                                    v-if="userList.length > 0"
                                    class="absolute z-[80] md:w-[calc(100%-250px)] left-0 md:top-[80px] w-full top-[60px]"
                                >
                                    <div
                                        class="flex flex-col w-full p-3 bg-white border rounded-md shadow z-100"
                                    >
                                        <template
                                            v-for="user in userList"
                                            :key="user.id"
                                        >
                                            <a
                                                :href="user.route_url"
                                                class="flex items-center justify-between px-4 py-4 text-gray-700 rounded-md cursor-pointer hover:bg-gray-200 active:bg-main-lighter"
                                            >
                                                <div
                                                    class="flex items-center space-x-4"
                                                >
                                                    <Icon type="search" />
                                                    <span
                                                        class="font-bold lg:text-xl text-md overflow-hidden text-ellipsis whitespace-nowrap max-w-[165px] lg:max-w-full"
                                                        >{{
                                                            user.type
                                                                ? user.name
                                                                : user.fullname
                                                        }}</span
                                                    >
                                                    <span
                                                        class="hidden ml-4 text-gray-400 font-italic lg:block lg:text-sm"
                                                    >
                                                        {{
                                                            getUserTitle(user)
                                                        }}</span
                                                    >
                                                </div>
                                                <Avatar :user="user" />
                                            </a>
                                        </template>
                                        <a
                                            v-if="userList.length > 9"
                                            :href="route('list.users', searchItem)"
                                            class="flex items-center justify-between px-4 py-4 text-gray-700 rounded-md cursor-pointer hover:bg-gray-200 active:bg-main-lighter"
                                        >
                                            <p
                                                class="ml-4 font-bold lg:text-xl text-md"
                                            >
                                                {{ $t("See all...") }}
                                            </p>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </nav>
                    <div
                        class="flex flex-col h-[calc(100%_-_130px)] overflow-auto md:h-[calc(100%_-_100px)]"
                    >
                        <div
                            class="md:max-w-[1160px] md:overflow-auto pb-2 md:pb-4 flex flex-col flex-auto w-full md:px-6 mx-auto justify-start"
                            scroll-region
                            ref="scrollRegion"
                        >
                            <slot />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <ovn-toast ref="toast"></ovn-toast>
</template>

<script>
import Avatar from "@/Components/Avatar.vue";
import Dropdown from "@/Components/Dropdown.vue";
import DropdownLink from "@/Components/DropdownLink.vue";
import GenericButton from "@/Components/GenericButton.vue";
import NavLink from "@/Components/NavLink.vue";
import ResponsiveNavLink from "@/Components/ResponsiveNavLink.vue";
import OvnToast from "@/Components/Toast.vue";
import Icon from "@/Icons/perks-icons.vue";
import LeftCandidate from "@/Layouts/Left/LeftCandidate.vue";
import LeftHR from "@/Layouts/Left/LeftHR.vue";
import TopIconMenu from "@/Layouts/Partials/TopIconMenu.vue";
import { useFcmStore } from "@/Stores/useFcmStore.js";
import { hasPermissions } from "@/Utils/permissions.js";
import axios from "axios";
import { debounce, isEmpty } from "lodash";

export default {
    name: "AuthenticatedLayout",
    components: {
        OvnToast,
        Avatar,
        LeftHR,
        LeftCandidate,
        Dropdown,
        DropdownLink,
        Icon,
        NavLink,
        ResponsiveNavLink,
        TopIconMenu,
        GenericButton,
    },
    data() {
        return {
            showingNavigationDropdown: false,
            auth: this.$page.props.auth,
            isMenuOpen: false,
            isSmallerScreen: false,
            searchItem: "",
            showList: false,
            userList: [],
        };
    },
    props: {
        //company: Object,
        errors: Object,
        //auth: Object,
        left: String,
    },
    provide() {
        return {
            showToastGlobal: this.showToast,
        };
    },
    computed: {
        showLeft() {
            return (
                !hasPermissions(this.USER_PERMISSIONS.GUEST) ||
                this.left === "hr"
            );
        },
        leftComponent() {
            if (
                hasPermissions(this.USER_PERMISSIONS.HR_PORTAL) ||
                this.left === "hr"
            ) {
                return "LeftHR";
            } else {
                return "LeftCandidate";
            }
        },
        fcmStore() {
            return useFcmStore();
        },
        //locations() {
        //},
        //monthsList() {
        //},
    },
    watch: {
        searchItem(newVal) {
            if (!newVal) {
                this.showList = false;
            } else {
                this.showList = true;
            }
            this.searchUser(newVal);
        },
    },
    methods: {
        getUserTitle(user) {
            const candidatText = isEmpty(user.candidate_texts)
                ? null
                : user.candidate_texts;
            if (candidatText == null) return null;
            const candidateTexts = user.candidate_texts.filter((item) => {
                if (item.end_date == null) return true;
            });
            console.log(candidateTexts);
            return candidateTexts[0].title;
        },
        toggleMenu() {
            this.checkScreenSize();
            if (this.isSmallerScreen) {
                this.isMenuOpen = !this.isMenuOpen; // Toggle the menu on smaller screens
            }
        },
        checkScreenSize() {
            this.isSmallerScreen = window.innerWidth < 768; // Update isSmallerScreen based on window width
        },
        handleCloseMenu() {
            this.checkScreenSize();
            if (this.isSmallerScreen) {
                this.isMenuOpen = false;
            }
        },
        updateUserStatus() {
            if (this.auth.user) {
                axios.put(route("chat.user.update.status"));
            }
        },
        searchUser: debounce(function (query) {
            this.search(query.target.value);
        }, 600),

        async search(query) {
            // Perform search with query
            await axios
                .get(route("search.users", { search: query }))
                .then((response) => {
                    this.userList = response.data;
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        onPushMessageReceived(payload) {
            console.log("onPushMessageReceived", payload);

            let body = `<div><strong>${payload.notification.title}</strong>`;
            body += `<p>${payload.notification.body.replace(
                "\n",
                "<br>"
            )}</p></div>`;
            let message = "";
            if (payload.fcmOptions && payload.fcmOptions.link) {
                message = `<a href="${payload.fcmOptions.link}" class="w-full">`;
                message += body;
                message += `</a>`;
            } else {
                message = body;
            }
            this.$refs.toast.show(message, "info", 7000);
        },
        logout() {
            this.$inertia.post(route("logout"));
            this.auth.user = null;
        },
        showToast(message, type = "success") {
            this.$refs.toast.show(message, type);
        },
        showFlashMessage() {
            if (this.$page.props.toastMessage?.message) {
                let type = this.$page.props.toastMessage.type || "info";
                this.$refs.toast.show(
                    this.$page.props.toastMessage.message,
                    type
                );
                this.$page.props.toastMessage = null;
            }
        },
    },
    mounted() {
        // console.log(this.$page)
        this.checkScreenSize();
        this.showFlashMessage();
        // Open the menu by default on larger screens
        this.isMenuOpen = !this.isSmallerScreen;
        window.addEventListener("resize", this.checkScreenSize);
        this.updateUserStatus();
        this.fcmStore.firebaseInit((payload) => {
            this.onPushMessageReceived(payload);
        });
    },
    beforeUnmount() {
        // Remove resize event listener to prevent memory leaks
        window.removeEventListener("resize", this.checkScreenSize);
    },
    updated() {
        this.updateUserStatus();
        this.showFlashMessage();
    },
};
</script>

<style scoped></style>
