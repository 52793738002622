<script setup></script><template>
<svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
<g id="icon">
<g id="secondary-shape" opacity="0.32">
<path d="M12 11.1759C16.7038 11.1759 19.4773 11.074 20.868 11.0003C21.5117 10.9662 22 10.4336 22 9.78896V8.62016C22 8.17046 21.8551 7.72926 21.5366 7.41176C19.6683 5.54936 15.3747 3.1491 12.465 2.51396C12.1575 2.44684 11.8425 2.44684 11.535 2.51396C8.62535 3.1491 4.33171 5.54936 2.4634 7.41176C2.1449 7.72926 2 8.17046 2 8.62016V9.78896C2 10.4336 2.48829 10.9662 3.13195 11.0003C4.52271 11.074 7.29615 11.1759 12 11.1759Z" class=" group-[&.active]:fill-[#016A6D]  fill-[#637381]"/>
<path d="M2.1787 19.1545C2.30863 18.7773 2.68628 18.6058 3.0846 18.5832C4.11911 18.5244 6.6517 18.4259 12 18.4259C17.3483 18.4259 19.8809 18.5244 20.9154 18.5832C21.3137 18.6058 21.6914 18.7773 21.8213 19.1545C21.9176 19.434 22 19.8434 22 20.4259C22 21.0084 21.9176 21.4177 21.8213 21.6972C21.6914 22.0744 21.3137 22.246 20.9154 22.2686C19.8809 22.3273 17.3483 22.4259 12 22.4259C6.6517 22.4259 4.11911 22.3273 3.0846 22.2686C2.68628 22.246 2.30863 22.0744 2.1787 21.6972C2.08243 21.4177 2 21.0084 2 20.4259C2 19.8434 2.08243 19.434 2.1787 19.1545Z" class=" group-[&.active]:fill-[#016A6D]  fill-[#637381]"/>
</g>
<g id="primary-shape">
<path d="M12 9.42578C13.1046 9.42578 14 8.53035 14 7.42578C14 6.32121 13.1046 5.42578 12 5.42578C10.8954 5.42578 10 6.32121 10 7.42578C10 8.53035 10.8954 9.42578 12 9.42578Z" class=" group-[&.active]:fill-[#016A6D]  fill-[#637381]"/>
<path d="M13.9467 11.1696C13.3382 11.1735 12.6899 11.1757 12 11.1757C11.3101 11.1757 10.6618 11.1735 10.0533 11.1696C10.0277 11.8209 10 12.9769 10 14.9258C10 16.6453 10.0216 17.7475 10.0442 18.4305C10.65 18.4275 11.3009 18.4258 12 18.4258C12.6991 18.4258 13.35 18.4275 13.9558 18.4305C13.9784 17.7475 14 16.6453 14 14.9258C14 12.9769 13.9723 11.8209 13.9467 11.1696Z" class=" group-[&.active]:fill-[#016A6D]  fill-[#637381]"/>
<path d="M16.0542 11.147C16.0284 11.794 16 12.9542 16 14.9258C16 16.6601 16.022 17.7665 16.0448 18.4481C17.8468 18.4701 19.1022 18.505 19.9521 18.5381C19.976 17.8654 20 16.7386 20 14.9258C20 12.8442 19.9684 11.6672 19.9415 11.0435C19.0121 11.082 17.7369 11.1213 16.0542 11.147Z" class=" group-[&.active]:fill-[#016A6D]  fill-[#637381]"/>
<path d="M4.05845 11.0435C4.98788 11.082 6.26314 11.1213 7.94583 11.147C7.97163 11.794 8 12.9542 8 14.9258C8 16.6601 7.97805 17.7665 7.9552 18.4481C6.15324 18.4701 4.89779 18.505 4.04791 18.5381C4.024 17.8654 4 16.7386 4 14.9258C4 12.8442 4.03165 11.6672 4.05845 11.0435Z" class=" group-[&.active]:fill-[#016A6D]  fill-[#637381]"/>
</g>
</g>
</svg>
</template>
