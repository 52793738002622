<template>
    <modal @close="closeModal" :show="modalOpen">
        <form @submit.prevent="saveProfile">
            <div class="flex justify-between ">
                <h6 class="font-semibold text-center text-primary">
                    {{ editObject ? $t('Edit ') : $t('Add New ') }} {{ $t(modalTitle()) }}
                </h6>
                <div class="pb-3 text-gray-500 font-semibold  text-lm font-['Montserrat'] leading-snug">
                    <GenericButton
                        @click="closeModal"
                        type="button"
                        class="flex bg-transparent border border-transparent text-inherit hover:bg-secondary-12"
                        :text="$t('Back')"
                        iconPosition="left"
                        size="medium"
                        theme="inherit-outlined"
                        >
                        <template #leftIcon>
                            <span  class="transform rotate-90">
                                <IconDown />
                            </span>
                        </template>
                    </GenericButton>
                </div>
            </div>

            <div class="flex flex-row mb-4" v-if="showTitle()">
                <div class="w-full ml-3">
                    <div class="flex flex-row">
                        <div class="w-full ">
                            <InputLabel for="title" :value="$t('Name')" class="mb-4 mr-4" />
                            <TextInput id="title" type="text" class="block w-full mt-1 border" v-model="form.title"
                                autofocus />

                            <InputError class="mt-2" :message="form.errors?.title" />
                        </div>
                    </div>
                </div>
            </div>

            <div class="flex flex-row ">
                <div class="w-full ml-3">
                    <div class="flex-row mb-4" :class="category === 'culture' ? 'h-36' : 'h-10'" v-if="showText()">
                        <InputLabel for="text" value="Text" class="mb-4 mr-4" />

                        <QuillEditor v-if="category === 'culture'" theme="snow" v-model:content="form.text" />
                        <textarea v-else v-model="form.text" id="text" name="text" rows="3"
                            class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-main sm:text-sm sm:leading-6"></textarea>

                        <InputError class="mt-2" :message="form.errors?.text" />
                    </div>
                    <div class="flex flex-row mb-4" :class="{'mt-24': props?.category !== 'candidates/banner' && props?.category !== 'candidates/avatar'}" >
                        <div class="w-full mr-3">
                            <InputLabel for="filepond--browser-9iez9coiy" value="Image" class="mb-4 mr-4" />
                            <FileUploader @update:modelValue="fileUploaderValue" id="adasjkdzxhcaoqoiwui00000001235678911"  :isBrowse="isBrowse" />
                            <InputError class="mt-2" :message="form.errors?.file" />
                            <div class="grid grid-cols-4 mb-4 " v-if="editObject">
                                <div>
                                    <img class="h-[6rem] w-fit rounded-md" :src="path + '' + editObject?.media1?.url"
                                        alt="">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div
                class=" flex justify-end mr-3 pb-3 text-gray-500 font-semibold  text-lm font-['Montserrat'] leading-snug space-x-2">
                <GenericButton
                    type="button"
                    @click="closeModal"
                    :text="$t('Discard') "
                    theme="inherit-outlined"
                    size="medium"
                    class="border border-transparent bg-secondary-12 text-inherit hover:bg-secondary-10"
                />
                <GenericButton
                    type="submit"
                    size="medium"
                    theme="inherit-outlined"
                    class="cursor-pointer hover:text-white hover:bg-secondary-16 "
                    :text="$t('Save Changes')"
                />

            </div>
        </form>
    </modal>
</template>

<script setup>
import Modal from "@/Components/Modal.vue";
import InputError from '@/Components/InputError.vue';
import InputLabel from '@/Components/InputLabel.vue';
import PropertyButton from '@/Components/PropertyButton.vue';
import TextInput from '@/Components/TextInput.vue';
import FileUploader from "@/Components/FileUploader.vue";
import { useForm } from '@inertiajs/vue3';
import { QuillEditor } from '@vueup/vue-quill';
import { watch, ref } from "vue";
import GenericButton from "@/Components/GenericButton.vue";
import IconDown from '@/Icons/arrow-down.vue';

const emit = defineEmits(['update:toggleModal', 'update:ImageStore']);
const path = ref(import.meta.env.VITE_APP_URL);

const props = defineProps({
    modalOpen: Boolean,
    url: String,
    updateRoute: String,
    title: String,
    category: String,
    editObject: Object,
    isBrowse:{
        type: Boolean,
        default: false
    }
});

const form = useForm({
    title: null,
    text: null,
    type: null,
    category: null,
    file: null
});

const fileUploaderValue = (filePath) => {
    form.file = filePath[0];
}

const saveProfile = () => {

    form.type = props.category
    form.category = props.category
    if (props.editObject) {
        form.post(route(props.updateRoute,{organizationText: props.editObject?.id}), {
            onSuccess: () => {
                closeModal();
                emit('update:ImageStore');
                form.reset();
            },
        });
    } else {
        form.post(props.url, {
            onSuccess: () => {
                closeModal();
                emit('update:ImageStore');
                form.reset();
            },
        });
    }
}
const showTitle = () => {
    const hidden = (props.category === 'candidates/avatar') || (props.category === 'candidates/banner');
    return !hidden;
}
const showText = () => {
    const hidden = (props.category === 'candidates/avatar') || (props.category === 'candidates/banner');
    return !hidden;
}
const modalTitle = () => {
    let ret;
    if (props.category === 'candidates/avatar') {
        ret = 'avatar';
    } else if (props.category === 'candidates/banner') {
        ret = 'avatar';
    } else {
        ret = props.title;
    }
    return ret;

}

watch(() => props.editObject ,(newValue)=>{
    form.title = newValue?.title;
    form.text = newValue?.text;
})

const closeModal = () => {
    emit("update:toggleModal");
    form.clearErrors();
}
</script>

<style scoped>
.v-menu__content {
    display: flex;
    flex-wrap: wrap;
    max-height: none;
    min-width: auto;
}
</style>
