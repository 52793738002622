<template>
<div class="w-full h-min">
	<svg xmlns="http://www.w3.org/2000/svg" class="w-full" width="565" height="32" viewBox="0 0 565 121" fill="none">
<path d="M107.339 0.248047H13.2701C6.11166 0.248047 0.304688 6.05502 0.304688 13.2135V107.283C0.304688 114.441 6.11166 120.248 13.2701 120.248H107.339C114.498 120.248 120.305 114.441 120.305 107.283V13.2135C120.305 6.05502 114.498 0.248047 107.339 0.248047ZM67.7897 30.9836H82.6758V56.1062C77.8776 58.1496 72.9048 59.6046 67.7897 60.4517V30.9836ZM36.3493 30.9836H51.3194V61.1372C46.1979 60.7233 41.1734 59.7146 36.3493 58.1626V30.9836ZM51.3129 89.5125H36.3428V67.7719C41.1992 69.3303 46.1979 70.3262 51.3129 70.7594V89.5125ZM82.6693 89.5125H67.7833V70.2098C72.9177 69.4144 77.9099 68.05 82.6693 66.1488V89.5189V89.5125ZM82.6693 62.1912C77.9616 64.1829 72.9759 65.6315 67.7833 66.4721C64.356 67.0282 60.8317 67.3192 57.2492 67.3192C55.2446 67.3192 53.2658 67.2287 51.3129 67.0541C46.1267 66.5885 41.1152 65.5151 36.3428 63.9049V62.0037C41.1346 63.4781 46.1397 64.4416 51.3129 64.8361C53.1106 64.9783 54.9213 65.0495 56.7513 65.0495C60.5019 65.0495 64.1879 64.752 67.7833 64.1765C72.9565 63.3552 77.9422 61.9584 82.6693 60.0573C85.7797 58.8092 88.7867 57.3413 91.6578 55.6665C91.8906 56.1515 92.0975 56.6559 92.2657 57.1667C89.2264 59.0938 86.019 60.7815 82.6693 62.1977V62.1912ZM96.0486 56.7917C95.9257 58.0268 95.6347 59.2684 95.1562 60.4776C95.001 60.8656 94.8264 61.2471 94.6454 61.5963C94.7747 59.7081 94.4966 57.8392 93.8629 56.1062C93.6948 55.6277 93.4878 55.1621 93.2615 54.703C92.3174 52.8212 90.9206 51.1528 89.1617 49.8789C89.8407 50.0406 90.5197 50.241 91.1793 50.5062C92.1622 50.9006 93.0675 51.3856 93.8952 51.9676C94.4578 52.3621 94.917 52.86 95.2662 53.4161C95.5248 53.817 95.7188 54.2503 95.8546 54.703C96.0551 55.369 96.1262 56.0739 96.0615 56.7852L96.0486 56.7917Z" fill="#016A6D"/>
<path d="M82.5647 62.1432C77.8571 64.1349 72.8714 65.5834 67.6787 66.424C64.2514 66.9802 60.7272 67.2712 57.1447 67.2712C55.14 67.2712 53.1613 67.1806 51.2084 67.006C46.0222 66.5404 41.0106 65.467 36.2383 63.8568V61.9556C41.03 63.43 46.0351 64.3935 51.2084 64.788C53.0061 64.9303 54.8167 65.0014 56.6468 65.0014C60.3974 65.0014 64.0833 64.7039 67.6787 64.1284C72.852 63.3072 77.8377 61.9104 82.5647 60.0092C85.6752 58.7612 88.6821 57.2933 91.5533 55.6184C91.7861 56.1034 91.993 56.6078 92.1611 57.1187C89.1218 59.0457 85.9144 60.7335 82.5647 62.1496V62.1432ZM95.944 56.7436C95.8212 57.9787 95.5302 59.2203 95.0517 60.4295C94.8965 60.8175 94.7219 61.1991 94.5408 61.5483C94.6701 59.66 94.3921 57.7912 93.7583 56.0581C93.5902 55.5796 93.3833 55.114 93.157 54.6549C92.2128 52.7731 90.8161 51.1048 89.0572 49.8308C89.7362 49.9925 90.4151 50.193 91.0747 50.4581C92.0576 50.8526 92.963 51.3376 93.7907 51.9195C94.3533 52.314 94.8124 52.8119 95.1616 53.3681C95.4203 53.769 95.6143 54.2022 95.7501 54.6549C95.9505 55.321 96.0217 56.0258 95.957 56.7371L95.944 56.7436Z" fill="#FD8A17"/>
<path d="M363.865 27.5645C359.281 27.5645 355.402 31.3531 355.402 35.938C355.402 40.5243 359.281 44.4015 363.865 44.4015C368.45 44.4015 372.329 40.5243 372.329 35.938C372.329 31.3531 368.45 27.5645 363.865 27.5645Z" fill="#016A6D"/>
<path d="M371.183 92.1065V48.0198H356.548V92.1065H371.183Z" fill="#016A6D"/>
<path d="M200.851 30.29V92.0113H185.153V68.2888H167.781V92.0113H152V30.29H167.781V53.1263H185.153V30.29H200.851Z" fill="#016A6D"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M206.597 70.0598C206.597 56.9216 217.092 46.7838 230.14 46.7838C243.188 46.7838 253.684 56.9216 253.684 70.0598C253.684 83.198 243.278 93.3359 230.14 93.3359C217.003 93.3359 206.597 83.198 206.597 70.0598ZM239.043 70.0598C239.043 64.5887 235.254 60.8899 230.14 60.8899C225.026 60.8899 221.32 64.5954 221.32 70.0598C221.32 75.5229 225.109 79.2297 230.14 79.2297C235.171 79.2297 239.043 75.5229 239.043 70.0598Z" fill="#016A6D"/>
<path d="M276.589 93.3426C285.587 93.3426 295.637 89.554 295.637 78.5299C295.637 67.6293 286.393 65.4976 279.925 64.0069C276.444 63.2038 273.768 62.5871 273.768 60.8899C273.768 59.572 275.003 58.7757 277.028 58.7757C278.3 58.7757 279.384 59.1243 280.306 59.8C281.175 60.4368 281.899 61.3645 282.498 62.5643L294.84 57.0114C291.23 49.7828 284.613 46.7838 276.678 46.7838C267.686 46.7838 258.955 51.6301 258.955 61.5964C258.955 71.7195 268.002 74.0536 274.362 75.6945C277.809 76.5833 280.467 77.2684 280.467 78.8798C280.467 80.4658 279.06 81.2621 276.5 81.2621C273.06 81.2621 270.768 79.6695 269.622 76.4091L256.834 82.8468C260.362 90.3436 267.59 93.3426 276.589 93.3426Z" fill="#016A6D"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M350.199 70.0598C350.199 83.2865 341.289 93.3359 329.476 93.3359C323.834 93.3359 319.777 91.3933 316.957 88.0431V109.734H302.322V48.0131H316.957V52.0699C319.777 48.7196 323.834 46.7771 329.476 46.7771C341.289 46.7771 350.199 56.8331 350.199 70.0598ZM335.476 70.0598C335.476 64.3286 331.687 60.4502 326.216 60.4502C320.745 60.4502 316.957 64.3286 316.957 70.0598C316.957 75.791 320.745 79.6695 326.216 79.6695C331.687 79.6695 335.476 75.791 335.476 70.0598Z" fill="#016A6D"/>
<path d="M398.501 62.0361V74.8231C398.501 78.9697 401.762 79.2297 407.672 78.8798V92.1079C389.681 94.3105 383.86 88.7576 383.86 75.181V62.0428H377.25V48.0198H383.86V40.0846L398.495 35.678V48.0198H407.665V62.0428L398.501 62.0361Z" fill="#016A6D"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M460.126 92.1065V48.0185L445.492 48.0265V52.0833C442.671 48.733 438.614 46.7905 432.972 46.7905C421.159 46.7905 412.25 56.8398 412.25 70.0665C412.25 83.2932 421.159 93.3426 432.972 93.3426C438.614 93.3426 442.671 91.4 445.492 88.0498V92.1065H460.126ZM436.225 60.4502C441.697 60.4502 445.485 64.3286 445.485 70.0598C445.485 75.791 441.697 79.6695 436.225 79.6695C430.755 79.6695 426.966 75.791 426.966 70.0598C426.966 64.3286 430.755 60.4502 436.225 60.4502Z" fill="#016A6D"/>
<path d="M467.903 27.7361H482.538V92.1065H467.903V27.7361Z" fill="#016A6D"/>
<path d="M498.325 27.5645C493.74 27.5645 489.861 31.3531 489.861 35.938C489.861 40.5243 493.74 44.4015 498.325 44.4015C502.91 44.4015 506.788 40.5243 506.788 35.938C506.788 31.3531 502.91 27.5645 498.325 27.5645Z" fill="#016A6D"/>
<path d="M505.642 92.1065V48.0198H491.008V92.1065H505.642Z" fill="#016A6D"/>
<path d="M532.274 62.0361V74.8231C532.274 78.9697 535.535 79.2297 541.445 78.8798V92.1079C523.454 94.3105 517.633 88.7576 517.633 75.181V62.0428H511.023V48.0198H517.633V40.0846L532.268 35.678V48.0198H541.438V62.0428L532.274 62.0361Z" fill="#016A6D"/>
<path d="M555.55 27.5645C550.965 27.5645 547.087 31.3531 547.087 35.938C547.087 40.5243 550.965 44.4015 555.55 44.4015C560.135 44.4015 564.014 40.5243 564.014 35.938C564.014 31.3531 560.135 27.5645 555.55 27.5645Z" fill="#016A6D"/>
<path d="M562.868 92.1065V48.0198H548.233V92.1065H562.868Z" fill="#016A6D"/>
</svg>
</div>
</template>
