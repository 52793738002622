<script setup></script><template>
<svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
<g id="icon">
<path id="secondary-shape" opacity="0.32" fill-rule="evenodd" clip-rule="evenodd" d="M2.12411 8.42578H21.8758C21.9467 9.62687 22 11.2524 22 13.4258C22 16.91 21.8631 18.9863 21.7362 20.1629C21.6422 21.0346 21.0518 21.7068 20.1903 21.87C18.8494 22.124 16.3428 22.4258 12 22.4258C7.65725 22.4258 5.15055 22.124 3.80967 21.87C2.94826 21.7068 2.35785 21.0346 2.26379 20.1629C2.13682 18.9863 2 16.91 2 13.4258C2 11.2524 2.05324 9.62687 2.12411 8.42578ZM6.5 13.1073C6.5 12.5063 6.93315 12.0339 7.5337 12.0126C8.3136 11.9848 9.6669 11.9532 12 11.9327C14.5069 11.9105 15.8826 11.9454 16.6312 11.9857C17.1554 12.0139 17.5 12.4204 17.5 12.9454V13.3365C17.5 13.8983 17.1157 14.3351 16.5543 14.3597C15.7898 14.3932 14.4235 14.4258 12 14.4258C9.5765 14.4258 8.21025 14.3932 7.44565 14.3597C6.88435 14.3351 6.5 13.8983 6.5 13.3365V13.1073ZM6.5 17.5148C6.5 16.9533 6.8844 16.5051 7.44515 16.4761C7.97275 16.4488 8.7795 16.4258 10 16.4258C11.2205 16.4258 12.0272 16.4488 12.5548 16.4761C13.1156 16.5051 13.5 16.9533 13.5 17.5148V17.8368C13.5 18.3983 13.1156 18.8466 12.5548 18.8756C12.0272 18.9028 11.2205 18.9258 10 18.9258C8.7795 18.9258 7.97275 18.9028 7.44515 18.8756C6.8844 18.8466 6.5 18.3983 6.5 17.8368V17.5148Z" class=" group-[&.active]:fill-[#016A6D]  fill-[#637381]"/>
<g id="primary-shape">
<path d="M17.915 2.45704C18.2276 2.48281 18.4424 2.71176 18.4636 3.02467C18.4831 3.31214 18.5 3.75496 18.5 4.42578C18.5 4.53354 18.4995 4.63542 18.4987 4.73172C19.1999 4.8121 19.7561 4.89935 20.1902 4.98159C21.0516 5.1448 21.642 5.81693 21.7361 6.68863C21.7839 7.13128 21.833 7.70133 21.8758 8.42578H2.12402C2.16677 7.70133 2.21593 7.13128 2.2637 6.68863C2.35776 5.81693 2.94817 5.1448 3.80958 4.98159C4.24369 4.89934 4.8 4.81209 5.50125 4.7317C5.50045 4.63541 5.5 4.53354 5.5 4.42578C5.5 3.75496 5.5169 3.31214 5.5364 3.02467C5.5576 2.71176 5.7724 2.48281 6.08495 2.45704C6.29765 2.4395 6.5938 2.42578 7 2.42578C7.4062 2.42578 7.70235 2.4395 7.91505 2.45704C8.2276 2.48281 8.4424 2.71176 8.4636 3.02467C8.4831 3.31214 8.5 3.75496 8.5 4.42578C8.5 4.45118 8.5 4.47625 8.49995 4.50099C9.50645 4.45422 10.6666 4.42578 11.9999 4.42578C13.3332 4.42578 14.4935 4.45423 15.5 4.501L15.5 4.42578C15.5 3.75496 15.5169 3.31214 15.5364 3.02467C15.5576 2.71176 15.7724 2.48281 16.0849 2.45704C16.2976 2.4395 16.5938 2.42578 17 2.42578C17.4062 2.42578 17.7023 2.4395 17.915 2.45704Z" class=" group-[&.active]:fill-[#016A6D]  fill-[#637381]"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M7.5337 12.0126C6.93315 12.0339 6.5 12.5063 6.5 13.1073V13.3365C6.5 13.8983 6.88435 14.3351 7.44565 14.3597C8.21025 14.3932 9.5765 14.4258 12 14.4258C14.4235 14.4258 15.7897 14.3932 16.5543 14.3597C17.1156 14.3351 17.5 13.8983 17.5 13.3365V12.9454C17.5 12.4204 17.1554 12.0139 16.6312 11.9857C15.8825 11.9454 14.5069 11.9105 12 11.9327C9.6669 11.9532 8.3136 11.9848 7.5337 12.0126ZM7.44515 16.4761C6.8844 16.5051 6.5 16.9533 6.5 17.5148V17.8368C6.5 18.3983 6.8844 18.8466 7.44515 18.8756C7.97275 18.9028 8.7795 18.9258 10 18.9258C11.2205 18.9258 12.0272 18.9028 12.5548 18.8756C13.1156 18.8466 13.5 18.3983 13.5 17.8368V17.5148C13.5 16.9533 13.1156 16.5051 12.5548 16.4761C12.0272 16.4488 11.2205 16.4258 10 16.4258C8.7795 16.4258 7.97275 16.4488 7.44515 16.4761Z" class=" group-[&.active]:fill-[#016A6D]  fill-[#637381]"/>
</g>
</g>
</svg>
</template>
